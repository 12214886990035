<template>
  <div>
    <Table
      label="Banner"
      :actions="actions"
      :items="$store.getters['banner/tableData']"
      :fields="$store.getters['banner/tableFields']"
      :loading="$store.state.banner.loadingTable"
      :filter="$store.state.banner.tableFilter"
      :sort="$store.state.banner.tableSort"
      :sort-options="sortOptions"
      @changeFields="changeFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Sort
      sortOptions: [
        {
          key: 'createdAt',
          direction: 'desc',
          label: 'Newest',
          default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: ['view', 'create', 'update'],
    }
  },
  methods: {
    changeFields(val) {
      this.$store.commit('banner/SET_TABLE_FIELDS', val)
    },
    loadData() {
      return this.$store.dispatch('banner/getTableData')
    },
    filterData(val) {
      this.$store.commit('banner/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('banner/SET_TABLE_SORT', val)
    },
  },
}
</script>
